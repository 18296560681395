<!--
  PACKAGE_NAME : src/components/euc/license
  FILE_NAME : license-chart.vue
  AUTHOR : jhcho
  DATE : 2024-06-28
  DESCRIPTION :
-->
<template>
  <DxChart
      id="chart"
      :data-source="dataSource"
  >
    <DxCommonSeriesSettings
        argument-field="ymd"
        type="stackedbar"
    />
    <DxValueAxis :max-value-margin="0.1" position="left">
      <DxVisualRange :start-value="40"/>
      <DxConstantLine
          v-for="(license, index) in licenses"
          :key="`license_${index}`"
          :width="2"
          :value="license.value"
          :color="getLicenseColor(license)"
          dash-style="dash"
      >
        <DxLabel :text="`${license.key} (${license.value.toLocaleString()})`"/>
      </DxConstantLine>
    </DxValueAxis>
    <DxSeries
        v-for="(result, index) in results"
        :value-field="result.value"
        :name="result.name"
        :key="`result_${index}`"
        :stack="result.stack"
    />
    <DxLegend
        vertical-alignment="top"
        horizontal-alignment="center"
        item-text-position="right"
    >
      <DxBorder :visible="true"/>
    </DxLegend>
    <DxTooltip :enabled="true" />
  </DxChart>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxValueAxis,
  DxVisualRange,
  DxConstantLine,
  DxLabel,
  DxLegend,
  DxBorder,
  DxTooltip,
} from 'devextreme-vue/chart';
import { getPastFromToday, isSuccess } from "@/plugins/common-lib";

export default {
  props: {
    searchYmd: {
      type: String,
      default: null
    }
  },
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxVisualRange,
    DxConstantLine,
    DxLabel,
    DxLegend,
    DxBorder,
    DxTooltip,
  },
  data() {
    return {
      dataSource: [],
      results: [],
      licenses: [],
      enhancedAverage: 3940,
      basicAverage: 48,
      lastSearchDate: `${getPastFromToday(30, 'days', 'YYYY-MM-DD')} ~ ${getPastFromToday(0, 'days', 'YYYY-MM-DD')}`
    }
  },
  computed: {
    getLastSearchDate() {
      return this.lastSearchDate;
    }
  },
  methods: {
    async selectDataList(sort = '+regDt,+licenseOrd') {
      const ymd = this.searchYmd === null ? `${getPastFromToday(30, 'days', 'YYYY-MM-DD')}~${getPastFromToday(0, 'days', 'YYYY-MM-DD')}` : this.searchYmd;
      const payload = {
        actionname: 'EUC_CISCO_LICENSE_HISTORY_SELECT',
        data: {
          sort,
          ymd
        },
        loading: false,
      }
      this.lastSearchDate = `${getPastFromToday(30, 'days', 'YYYY-MM-DD')} ~ ${getPastFromToday(0, 'days', 'YYYY-MM-DD')}`;
      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        const transformedData = res.data.data.reduce((acc, cur) => {
          const existingEntry = acc.find(entry => entry.ymd === cur.ymd);

          if (existingEntry) {
            existingEntry[cur.model.replace(/\s/g, '')] = cur.cnt;
          } else {
            acc.push({
              ymd: cur.ymd,
              [cur.model.replace(/\s/g, '')]: cur.cnt
            });
          }

          return acc;
        }, []);
        this.results = [];

        const models = Array.from(new Set(res.data.data.map(item => item.model)));
        models.forEach((item) => {
          this.results.push({ name: item, value: item.replace(/\s/g, ''), stack: item.license });
        })
        const licenses = Array.from(new Set(res.data.data.filter(item => item.license !== null).map(item => item.license)));
        this.getLicense(licenses);

        this.dataSource = transformedData;
        }
    },
    /**
     * getLicense License 배열을 받아 각 라이선스에 대한 정보를 가져옴
     * U_SYSTEM_SETTING 에서 'license_euc'에 대한 설정 값을 가져와 JSON으로 파싱
     * 각 License 항목에 대해 설정에서 해당 라이선스 키를 찾아 this.licenses 배열에 추가
     *
     * @param {Array} licenses - 라이선스 키의 배열
     */
    getLicense(licenses) {
      const licenseConfig = JSON.parse(this.$_getSystemData('license_euc')?.configValue);
      licenses.forEach((item) => {
        licenseConfig.find((license) => {
          if (license.key === item) {
            this.licenses.push(license);
          }
        });
      });
    },
    /**
     * License 객체의 color 반환
     * color 속성이 존재하지 않는 경우, 랜덤한 CSS hex 색상 코드를 생성하여 반환
     *
     * @param {Object} license - color 속성을 가진 라이선스 객체
     * @returns {string} 라이선스의 color 속성 또는 랜덤 생성된 CSS hex 색상 코드
     */
    getLicenseColor(license) {
      return license?.color ? license.color : `#${Math.floor(Math.random()*16777215).toString(16)}`;
    }
  },
  mounted() {
    this.selectDataList();
  },
  created() {
  }
}
</script>
<style scoped>
</style>