<!--
  PACKAGE_NAME : src/components/euc/license
  FILE_NAME : license-card.vue
  AUTHOR : jhcho
  DATE : 2024-06-27
  DESCRIPTION :
-->
<template>
  <div class="rounded-xl h-full grid justify-center content-center" style="box-shadow: 0px 1px 6px 2px rgb(92 92 92 / 10%);">
    <div class="w-full">
      <div class="w-28 h-full mr-1 flex-shrink-0">
        <img class="w-full h-full" v-if="image" :src="image" />
      </div>
    </div>
    <div class="w-full items-center text-center">
      <span class="text-base w-full">{{ modelNm ? modelNm : '모델명 없음' }}</span>
    </div>
    <div class="w-full items-center text-center">
      <span class="text-base w-full">{{ cnt.toLocaleString() }}개</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    model: {
      type: String,
      default: 'None'
    },
    modelNm: {
      type: String,
      default: ''
    },
    cnt: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      image: null
    }
  },
  created() {
    this.image = this.$_getAttachFileURL(null, this.model);
  },
}
</script>
<style scoped>
</style>