<!--
  PACKAGE_NAME : src/pages/euc/main
  FILE_NAME : kb-securities-main.vue
  AUTHOR : jhcho
  DATE : 2024-06-26
  DESCRIPTION :
-->
<template>
  <div class="page-sub-box bgstyle-02">
    <div class="main-container clearfix ui-glid-box ui-51">
      <div class="main-dash-info">
      </div>
      <div class="w-4/12 float-left pr-7">
        <div class="main-board-group3">
          <div class="board-group-top">
            <span class="text-xl font-semibold">
              {{ $_msgContents('UC.WORD.LICENSE_STATUS', { defaultValue: '라이센스 현황' }) }}
            </span>

            <div class="refresh-time-box fr flex items-center">
              <span>
                {{ statusLastSearchDate }}
              </span>
              <button class="btn-icon" type="button" style="border: 0;" @click="refreshData('STATUS')">
                <img src="@/assets/images/icon/refresh_icon.png"/>
              </button>
            </div>
          </div>
          <div class="scroll-body-box scrollbar-dynamic w-full h-full">
            <LicenseStatus ref="licenseStatusRef" />
          </div>
        </div>
      </div>
      <div class="w-8/12 float-right">
        <div class="main-board-group3">
          <div class="board-group-top clearfix">
            <span class="text-xl font-semibold">
              {{ $_msgContents('UC.WORD.LICENSE_HISTORY', { defaultValue: '라이센스 이력' }) }}
            </span>

            <div class="refresh-time-box fr flex items-center">
              <span>
                {{ chartLastSearchDate }}
              </span>
              <button class="btn-icon" type="button" style="border: 0;" @click="refreshData('CHART')">
                <img src="@/assets/images/icon/refresh_icon.png"/>
              </button>
            </div>
          </div>

          <div class="board-group-box">
            <div class="sub_new_style01 sub_ui_box1">
              <LicenseChart  ref="licenseChartRef" :search-ymd="`${startYmd}~${today}`" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full">
      <div class="main-board-group3">
        <div class="board-group-top clearfix">
          <span class="text-xl font-semibold">
            {{ $_msgContents('UC.WORD.RECENT_WORK_HISTORY', { defaultValue: '최근 작업 이력' }) }}
          </span>
          <div class="refresh-time-box fr flex items-center">
            <span>
              {{ historyLastSearchDate }}
            </span>
            <button class="btn-icon" type="button" style="border: 0;" @click="refreshData('HISTORY')">
              <img src="@/assets/images/icon/refresh_icon.png"/>
            </button>
          </div>
        </div>
        <div class="board-group-box">
          <div class="sub_new_style01 sub_ui_box1">
            <LicenseHistory ref="licenseHistoryRef" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LicenseStatus from '@/components/euc/license/license-status.vue'
import LicenseChart from "@/components/euc/license/license-chart.vue";
import LicenseHistory from "@/components/euc/license/license-history.vue";
import { getPastFromToday } from "@/plugins/common-lib";

export default {
  components: {
    LicenseStatus,
    LicenseChart,
    LicenseHistory
  },
  data() {
    return {
      startYmd: getPastFromToday(30, 'days', 'YYYY-MM-DD'),
      today: getPastFromToday(0, 'days', 'YYYY-MM-DD'),
      statusLastSearchDate: getPastFromToday(0, 'days', 'YYYY-MM-DD'),
      chartLastSearchDate: `${getPastFromToday(30, 'days', 'YYYY-MM-DD')}~${getPastFromToday(0, 'days', 'YYYY-MM-DD')}`,
      historyLastSearchDate: `${getPastFromToday(30, 'days', 'YYYY-MM-DD')}~${getPastFromToday(0, 'days', 'YYYY-MM-DD')}`
    }
  },
  methods: {
    refreshData(type) {
      switch (type) {
        case 'STATUS':
          this.statusLastSearchDate = getPastFromToday(0, 'days', 'YYYY-MM-DD');
          this.$refs.licenseStatusRef.selectDataList();
          break;
        case 'CHART':
          this.chartLastSearchDate = `${getPastFromToday(30, 'days', 'YYYY-MM-DD')} ~ ${getPastFromToday(0, 'days', 'YYYY-MM-DD')}`;
          this.$refs.licenseChartRef.selectDataList();
          break;
        case 'HISTORY':
          this.historyLastSearchDate = `${getPastFromToday(30, 'days', 'YYYY-MM-DD')} ~ ${getPastFromToday(0, 'days', 'YYYY-MM-DD')}`;
          this.$refs.licenseHistoryRef.selectDataList();
          break;
      }
    },
  },
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>

</style>